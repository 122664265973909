<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    :is-calculation-enable="true"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import { currencyFormat } from '@/libs/utils'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_pengdana',
      headers: [
        [{
          label: 'Jenis Penggunaan',
          dataKey: 'jenis_penggunaan',
          attributes: { rowspan: 2 }
        }, {
          label: 'Jumlah Dana (dalam jutaan)',
          attributes: { colspan: 4 }
        }],
        [{
          label: 'TS-2',
          dataKey: 'ts_2',
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center' },
          render(data) {
            return currencyFormat(data)
          },
          calculateRender(data) {
            return currencyFormat(data)
          },
        }, {
          label: 'TS-1',
          dataKey: 'ts_1',
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center' },
          render(data) {
            return currencyFormat(data)
          },
          calculateRender(data) {
            return currencyFormat(data)
          },
        }, {
          label: 'TS',
          dataKey: 'ts',
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center' },
          render(data) {
            return currencyFormat(data)
          },
          calculateRender(data) {
            return currencyFormat(data)
          },
        }, {
          label: 'Rata-Rata',
          dataKey: 'rata_rata',
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center' },
          render(data) {
            return currencyFormat(data)
          },
          calculateRender(data) {
            return currencyFormat(data)
          },
        }]
      ],
      form: {
        inputs: [{
          label: 'Jenis Penggunaan',
          name: 'jenis_penggunaan',
          dataKey: 'jenis_penggunaan',
          type: 'select',
          rules: 'required',
          options: [
            'Biaya operasional pendidikan',
            'Biaya kegiatan penelitian',
            'Biaya kegiataan pengabdian kepada masyarakat',
            'Biaya kegiatan publikasi',
            'Biaya kegiatana kemahasiswaan',
            'Biaya investasi sumber daya manusia (SDM)',
            'Investasi prasarana pendidikan',
            'Investasi sarana pendidikan',
          ]
        }, {
          label: 'TS-2',
          name: 'ts_2',
          dataKey: 'ts_2',
          type: 'currency',
          defaultValue: 0,
          currencyConfig: { precision: 0 }
        }, {
          label: 'TS-1',
          name: 'ts_1',
          dataKey: 'ts_1',
          type: 'currency',
          defaultValue: 0,
          currencyConfig: { precision: 0 }
        }, {
          label: 'TS',
          name: 'ts',
          dataKey: 'ts',
          type: 'currency',
          defaultValue: 0,
          currencyConfig: { precision: 0 }
        }]
      },
    }
  },
}
</script>
